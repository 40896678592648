import { createSlice } from "@reduxjs/toolkit";
import { getCollectionCategory, getProduct } from "./product.api";


const authSlice = createSlice({
    name: 'product',
    initialState: {
        loading: false,
        productList: [],
        collectionCategoryList: [],
        activeTab: 0,
        loadingCategory: false
    },
    reducers: {
        handleActiveTab: (state, { payload }) => {
            state.activeTab = payload
        },
    },
    extraReducers: {
        [getProduct.pending]: (state, action) => {
            state.loading = true
        },
        [getProduct.fulfilled]: (state, { payload }) => {
            state.loading = false
            // console.log(payload)
            state.productList = payload.data
        },
        [getProduct.rejected]: (state, action) => {
            state.loading = false
        },

        //collection
        [getCollectionCategory.pending]: (state, action) => {
            state.loadingCategory = true
        },
        [getCollectionCategory.fulfilled]: (state, { payload }) => {
            state.loadingCategory = false
            // console.log(payload)
            state.collectionCategoryList = payload.data
        },
        [getCollectionCategory.rejected]: (state, action) => {
            state.loadingCategory = false
        },

    }

})

export const { handleActiveTab } = authSlice.actions


export default authSlice.reducer