import React from 'react'
import LayoutApp from '../shared/components/LayoutApp'
import TableInvoice from './components/TableInvoice'


export default function Invoice() {
    return (
        <LayoutApp title='TAGIHAN'>
            <div className="invoice">
                <TableInvoice />
            </div>
        </LayoutApp>
    )
}
