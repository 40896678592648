
import React, { useEffect, useState } from 'react'
import { Button, Modal, Radio, Table, Tag } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { rupiahFormat } from '../../../app/helper'
import { FilePdfFilled } from '@ant-design/icons'
import moment from 'moment'
import { getOrder } from '../order.api'
import { getOrderStatuses } from '../../shared/shared.api'

export default function TableOrder() {
    const { loading, listOrder } = useSelector(state => state.order)
    const { orderStatusList } = useSelector(state => state.shared)

    const dispatch = useDispatch()

    const [activeTab, setActiveTab] = useState('all')
    const [arrayStatuses, setArrayStatuses] = useState([])
    useEffect(() => {
        dispatch(getOrder())
        dispatch(getOrderStatuses())
    }, [])

    useEffect(() => {
        const arrStatus = []
        for (const key in orderStatusList) {
            arrStatus.push(orderStatusList[key])
        }
        setArrayStatuses(arrStatus)
    }, [orderStatusList])


    const handleTab = (e) => {
        console.log(e.target.value)
        if (e.target.value === 'all') {
            dispatch(getOrder())
            setActiveTab('all')
        } else {
            const params = { 'status': e.target.value }
            dispatch(getOrder(params))
            setActiveTab(e.target.value)
        }

    }



    return (
        <>
            <div className=''>
                <Radio.Group disabled={loading} value={activeTab} onChange={handleTab} className='' size=''>
                    <Radio.Button className='me-2 mb-3 catbtn' key={'all'} value={'all'}>All Status</Radio.Button>
                    {
                        arrayStatuses.map(res => (
                            <Radio.Button className='me-2 mb-3 catbtn' type="primary" key={res.key} value={res.key}> {res.label} </Radio.Button>
                        ))
                    }
                </Radio.Group>
                <Table
                    columns={[
                        {
                            title: "ID pesanan ",
                            width: 120,
                            align: 'center',
                            render: (n) => <><p>{n.id}</p></>
                        },
                        {
                            title: "TANGGAL PESANAN PEMBELIAN",
                            // width: 150,
                            render: (n) => <p className='text-dark'>
                                {moment(n?.created_at).format('DD MMM YYYY HH:ss')}
                            </p>,
                            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at)
                        },
                        // {
                        //     title: "Shipping Total ",
                        //     render: (n) => <p>{rupiahFormat(n.shipping_total.value)} </p>
                        // },
                        // {
                        //     title: "Discount Total ",
                        //     render: (n) => <p>{rupiahFormat(n.discount_total.value)} </p>
                        // },
                        // {
                        //     title: "Sub Total + tax",
                        //     render: (n) => <p>{rupiahFormat(n.sub_total.value)} + <small>{rupiahFormat(n.tax_total.value)}</small> </p>
                        // },
                        {
                            title: "Total HARGA ",
                            render: (n) => <p> <b>{rupiahFormat(n.total.value)}</b> </p>,
                            sorter: (a, b) => a.total.value - b.total.value
                        },
                        {
                            title: "status",
                            render: (n) => <p>
                                <Tag color={orderStatusList[n?.status]?.color}>
                                    {orderStatusList[n?.status]?.label}
                                </Tag>
                            </p>
                        },
                        {
                            title: "CATATAN",
                            width: 250,
                            render: (n) => <p>{n.notes || '-'} </p>
                        },
                        {
                            title: "ACTION",
                            align: 'center',
                            width: 150,
                            render: (v) =>
                                <Button
                                    // size='small'
                                    className='p-0 d-flex align-items-center justify-content-center w-100'
                                    // shape="round"
                                    icon={<FilePdfFilled />}
                                    href={v.invoice_pdf}
                                    target="__blank"
                                // onClick={() => showModal(v)}
                                >
                                    Detail
                                </Button>
                        },
                    ]}
                    dataSource={listOrder}
                    size="middle"
                    loading={loading}
                    rowClassName={(_, index) => index % 2 === 0 ? '' : 'rowdark'}
                    pagination={{
                        size: 10,
                        showSizeChanger: true,
                        // onShowSizeChange: (e, v) => console.log(e, v),
                        showTotal: (total, range) => (
                            <span style={{ left: 0, position: "absolute", fontSize: 12 }}>
                                Showing {range[0]} to {range[1]} of {total} results
                            </span>
                        ),
                    }}
                    scroll={{
                        x: "100%",
                        // y: 300,
                    }}
                />

            </div>
        </>
    )
}
