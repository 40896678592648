import React, { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Checkbox, Form, Input, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postLogin } from '../auth.api';


import { handleRegister } from '../auth.reducer';


const Login = () => {
    const { loading, isRegister } = useSelector(state => state.auth)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onFinish = (values) => {
        // console.log('Success:', values);
        dispatch(postLogin(values))
            .then(unwrapResult)
            .then(async (res) => {
                const { access_token, permissions, expires_at, roles, customer, user } = res
                await localStorage.setItem('access_token', access_token)
                await localStorage.setItem('permissions', permissions)
                // localStorage.setItem('expires_at', expires_at)
                await localStorage.setItem('roles', roles)
                await localStorage.setItem('customer', JSON.stringify(customer))
                await localStorage.setItem('user', JSON.stringify(user))

                await navigate('/home')

            })
            .catch(err => {
                console.log(err)
                notification.error({
                    message: 'Error Login',
                    description:
                        'Please enter the correct username and password.',
                });
            })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <>
            <Form
                name="basic"
                layout="vertical"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="on"
                disabled={loading}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Silahkan masukan email anda!',
                        },
                    ]}
                >
                    <Input type='email' />
                </Form.Item>

                <Form.Item
                    label="Sandi"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Silahkan masukan kata sandi anda!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="remember_me"
                    valuePropName="checked"
                >
                    <Checkbox>Ingat saya</Checkbox>
                </Form.Item>

                <Form.Item
                >
                    <Button type="primary" htmlType="submit" size='large' className='w-100' loading={loading}>
                        Masuk
                    </Button>
                </Form.Item>
            </Form>
            {/* <p className="text-center text-primary pointer" onClick={() => dispatch(handleRegister())}>
                {isRegister ? 'Masuk Disini!' : 'Daftar Disini!'}
            </p> */}
        </>
    );
};
export default Login;