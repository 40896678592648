import React, { useEffect, useState } from 'react'
import { Button, Modal, notification, Select, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { rupiahFormat } from '../../../app/helper'
import { getCart, postCheckoutCart } from '../../cart/cart.api'
import { getAddressList, getShippingList } from '../../shared/shared.api'
import TextArea from 'antd/lib/input/TextArea'
import { unwrapResult } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'

export default function CartTable() {
    const { loadingCart, cartList, cartAllData, loadingCheckout } = useSelector(state => state.cart)
    const { loadingAddress, addressList, shippingList } = useSelector(state => state.shared)

    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(() => {
        dispatch(getCart())
        dispatch(getAddressList())
        dispatch(getShippingList())
    }, [])


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [AddressId, setAddressId] = useState();
    const [ShippingId, setShippingId] = useState();
    const [Notes, setNotes] = useState("");


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        const data = {
            address_id: AddressId,
            notes: Notes,
            shipping_identifier: ShippingId //WHATS MEAN ?
        }
        console.log(data)
        dispatch(postCheckoutCart(data)).then(unwrapResult)
            .then(res => {
                console.log(res, 'success')
                const key = `open${Date.now()}`;
                const btn = (
                    <Button type="primary" size="small" onClick={() => navigate('/order')}>
                        Buka halaman pesanan!
                    </Button>
                );
                notification.success({
                    message: 'Checkout berhasil',
                    // placement: 'top',
                    // description:''
                    key,
                    btn
                });
                handleExitModal()

                dispatch(getCart())
                dispatch(getCart())

            })
            .catch(err => {
                console.log(err)
                Modal.error({
                    title: 'Error',
                    content: 'Failed to Checkout!',
                });
            })
    };

    const handleExitModal = () => {
        setIsModalOpen(false);
        setAddressId()
        setShippingId()
    };

    const onChange = (value) => {
        // console.log(`selected ${value}`);
        setAddressId(value)
    };
    const onChangeShipping = (value) => {
        console.log(`selected ${value}`);
        setShippingId(value)
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };


    return (
        <div className=''>
            <Modal
                title={<p className='text-center mb-0'>Pilih Alamat</p>}
                open={isModalOpen}
                maskClosable={false}
                closable={false}
                footer={[
                    <Button key="back" disabled={loadingCheckout} onClick={handleExitModal}>
                        Batal
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingCheckout} disabled={!AddressId || !ShippingId} onClick={handleOk}>
                        Checkout
                    </Button>,
                ]}
            >
                <div className="pb-3">
                    <p className='mb-1'>Pilih Alamat <sup className='text-danger'><i>Required</i></sup></p>
                    <div className="mb-3">
                        <Select
                            // allowClear
                            value={AddressId}
                            className='w-100'
                            showSearch
                            placeholder="Pilih Alamat"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {addressList.map((res, i) => <Select.Option key={i} value={res.id}>
                                {`${res?.company_name}, ${res.line_one} ${res.line_two && res.line_two} ${res.line_three && res.line_three}`}
                            </Select.Option>)}

                        </Select>
                    </div>
                    <p className='mb-1'>Pilih Pengiriman <sup className='text-danger'><i>Required</i></sup></p>
                    <div className="mb-3">
                        <Select
                            // allowClear
                            value={ShippingId}
                            className='w-100'
                            showSearch
                            placeholder="Pilih Pengiriman"
                            optionFilterProp="children"
                            onChange={onChangeShipping}
                            onSearch={onSearch}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {shippingList.map((res, i) => <Select.Option key={i} value={res.identifier}>
                                {`${res?.name} `}
                            </Select.Option>)}

                        </Select>
                    </div>
                    <p className='mb-1'>Catatan </p>
                    <TextArea rows={4} value={Notes} onChange={(e) => setNotes(e.target.value)} />
                </div>
                <div className="d-flex justify-content-between align-items-center pt-3">
                    <p className="mb-0"> <b>TOTAL HARGA</b> </p>
                    <p className="mb-0">
                        <b>
                            {rupiahFormat(cartAllData?.sub_total)}
                        </b>
                    </p>
                </div>
            </Modal>
            {/* <Button onClick={() => dispatch(getCart())}>get data</Button> */}
            <Table
                columns={[
                    {
                        title: "No",
                        width: 50,
                        align: 'center',
                        render: (item, arr, i) => <p className='text-dark'>
                            <b className=''>{i + 1}</b>
                        </p>
                    },
                    {
                        title: "NAMA",
                        width: 150,
                        render: (n) => <p className='text-dark'>
                            <b className=''>{n?.description}</b>
                        </p>
                    },
                    {
                        title: "KATEGORI",
                        render: (n) => <><p>undefined</p></>
                    },
                    {
                        title: "HARGA / QTY ",
                        render: (n) => <p>{rupiahFormat(n.unit_price)} x {n.quantity} </p>
                    },
                    {
                        title: "SUB TOTAL",
                        render: (n) => <p>
                            <b>{rupiahFormat(n.sub_total)}</b>
                        </p>
                    },
                    // {
                    //     title: "HARGA",
                    //     align: 'center',
                    //     width: 150,
                    //     render: (v) => <>
                    //         {
                    //             v?.variants?.map(res => <div>
                    //                 <Button
                    //                     size=''
                    //                     className='d-flex align-items-center w-100'
                    //                     shape="round"
                    //                     icon={<ShoppingCartOutlined />}
                    //                     // onClick={() => showModal(v)}
                    //                 >
                    //                     <b> &nbsp;
                    //                         {rupiahFormat(res?.price?.value)}
                    //                     </b>
                    //                 </Button>
                    //             </div>)
                    //         }
                    //     </>,
                    // },
                ]}
                dataSource={[...cartList].reverse()}
                size="middle"
                loading={loadingCart}
                rowClassName={(_, index) => index % 2 === 0 ? '' : 'rowdark'}
                pagination={false}
                scroll={{
                    x: "100%",
                    // y: 300,
                }}
            />

            {
                cartAllData?.sub_total !== 0 &&
                <>
                    <div className="d-flex align-items-center justify-content-end mt-4">
                        <div className=" d-flex align-items-center justify-content-between" style={{ width: 400 }}>
                            <h5 className="mb-0"><b>TOTAL HARGA</b></h5>
                            <h5 className="mb-0"><b>{rupiahFormat(cartAllData?.sub_total)}</b> </h5>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end mt-4 mb-5">
                        <Button type="primary" size='large' style={{ width: 400 }} onClick={showModal}>CHECKOUT</Button>
                    </div>
                </>
            }

        </div>
    )
}
