import React from 'react'
import ProductTable from './components/ProductTable'
import LayoutApp from '../shared/components/LayoutApp'
import CategoryTab from './components/CategoryTab'


export default function Product() {
    return (
        <LayoutApp title='BERANDA' >
            <CategoryTab />
            <ProductTable />
        </LayoutApp>
    )
}
