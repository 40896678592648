import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../config";

export const getOrder = createAsyncThunk('order/getOrder', async (params, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${apiUrl}/api/orders`, {
            params,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
        // console.log(response)
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})
