import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../config";

export const postAddToCart = createAsyncThunk('cart/postAddToCart', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${apiUrl}/api/cart/items`, data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})


export const getCart = createAsyncThunk('cart/getCart', async (params, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${apiUrl}/api/cart`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
        console.log(response, 'get cart running')
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})


export const postCheckoutCart = createAsyncThunk('cart/postCheckoutCart', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${apiUrl}/api/checkout`, data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})