import { createSlice } from "@reduxjs/toolkit";
import { getCart, getOrder, postAddToCart, postCheckoutCart } from "./order.api";




const orderSlice = createSlice({
    name: 'order',
    initialState: {
        loading: false,
        listOrder: [],
    },
    extraReducers: {
        // get order
        [getOrder.pending]: (state, action) => {
            state.loading = true
        },
        [getOrder.fulfilled]: (state, { payload }) => {
            state.loading = false
            console.log(payload, 'order')
            state.listOrder = payload.data
        },
        [getOrder.rejected]: (state, action) => {
            state.loading = false
        },
    }

})

export default orderSlice.reducer