import React, { useState, useEffect } from 'react';
import { Button, Radio } from 'antd';
import Search from 'antd/lib/input/Search';
import { useDispatch, useSelector } from 'react-redux'

import { getCollectionCategory, getProduct } from '../product.api';
import { handleActiveTab } from '../product.reducer';
import { secondaryColor } from '../../../config';


export default function CategoryTab() {
    const dispatch = useDispatch()
    const { collectionCategoryList, loading, activeTab } = useSelector(state => state.product)
    const [tabListCategory, setTabListCategory] = useState([])
    const [searchText, setSearchText] = useState('')


    useEffect(() => {
        dispatch(getCollectionCategory())
    }, [])

    useEffect(() => {
        const refactorData = collectionCategoryList.map(res => (
            {
                key: res.id,
                label: res?.name,
                ...res
            }
        ))
        setTabListCategory(refactorData)
    }, [collectionCategoryList])

    const handleTab = (e) => {
        dispatch(handleActiveTab(e.target.value))
        const params = { collection_id: e.target.value }
        dispatch(getProduct(params))
        setSearchText('')
    }

    const handleChangeSearch = (e) => {
        setSearchText(e.target.value)
        if (e.target.value.length === 0) {
            dispatch(handleActiveTab(0))
            dispatch(getProduct());
        }
    }

    const handleSearch = (v) => {
        setSearchText(v)
        if (v) {
            const params = { search: v }
            dispatch(getProduct(params))
            dispatch(handleActiveTab(0))
        }
    }


    return (
        <div className='category-tabs mb-3'>
            <Radio.Group disabled={loading} value={activeTab} onChange={handleTab} className='mb-3' size='large'>
                <Radio.Button className='me-2 catbtn' key={0} value={0}>Semua Produk</Radio.Button>
                {
                    tabListCategory.map(res => (
                        <Radio.Button className='me-2 catbtn' type="primary" key={res.id} value={res.id}>{res.label}</Radio.Button>
                    ))
                }
            </Radio.Group>

            <Search className='bg-white'
                disabled={loading}
                value={searchText}
                width={400} allowClear
                enterButton={<Button disabled={loading} style={{ background: secondaryColor, color: 'white', border: '1px solid #E54E26' }}>Cari</Button>}
                onChange={handleChangeSearch} placeholder="Cari"
                onSearch={handleSearch} />
        </div>
    )
}
