import React from 'react'
import { useSelector } from 'react-redux'
import { primaryColor, webType } from '../../config'

import Login from './components/Login'
import Register from './components/Register'

import snaLogo from '../../images/Logo.png'
import hamLogo from '../../images/hamLogo.png'
import bgBlue from '../../images/bgBlueSNA2.png'
import bgOrange from '../../images/bgOrangeSNA.png'
import mslogo from '../../images/mslogo.png'



export default function Auth() {
    const { isRegister } = useSelector(state => state.auth)
    const bgImage = webType === 'SNA' ? bgBlue : bgOrange
    const logo = webType === 'SNA' ? snaLogo : hamLogo
    return (
        <div className='container-fluid no-gutters auth'>
            <div className="row">
                <div className="col-md bgauth"
                    style={{
                        backgroundImage: `url(${bgImage})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: webType === 'SNA' ? primaryColor : '#FEEEE3'
                    }}>
                </div>
                <div className="col-md-4 px-4">
                    <div className="formcon">

                        <div className="login-regis">
                            <div className="d-flex justify-content-center flex-column align-items-center mb-4">
                                <img src={logo} alt="logo" className='logo mb-3' />

                            </div>

                            <h2 className='text-center'>Buyer</h2>
                            {
                                isRegister ?
                                    <Register />
                                    :
                                    <Login />
                            }
                            <div className="d-flex justify-content-center flex-column align-items-center mb-4">

                                <img src={mslogo} alt="ms-logo" className='mslogo' />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
