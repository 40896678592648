import React from 'react';
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import routes from './routes';
import { PrivateRoute, PublicRoute } from './app/helper';
import { webType } from './config';
import { Helmet } from "react-helmet";


function App() {

  return (
    <div className={webType === 'SNA' ? 'sna-theme' : 'ham-theme'}>
      <Helmet>
        <title>{webType === 'SNA' ? 'SNA' : 'the Horeca'}</title>
        {/* <meta name="description" content="Nested component"> */}
      </Helmet>
      <Routes>
        {
          routes.map(({ path, element, isPrivate }) => (
            <Route
              key={path}
              path={path}
              element={
                isPrivate ?
                  <PrivateRoute>{element}</PrivateRoute>
                  :
                  <PublicRoute>{element}</PublicRoute>
              }
            />
          ))
        }
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

    </div>
  );
}

export default App;
