import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../config";

export const postProduct = createAsyncThunk('product/postProduct', async (data, { rejectWithValue }) => {
    try {

        const response = await axios.post(`${apiUrl}/api/auth/login`, data)
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})

export const getProduct = createAsyncThunk('product/getProduct', async (params, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${apiUrl}/api/products`, {
            params,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
        // console.log(response)
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})



export const getCollectionCategory = createAsyncThunk('product/getCollectionCategory', async (params, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${apiUrl}/api/collections`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
        // console.log(response)
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})

// export const getProductSearch = createAsyncThunk('product/getProductSearch', async (params, { rejectWithValue }) => {
//     try {
//         const response = await axios.get(`${apiUrl}/api/products/search`, {
//             params,
//             headers: {
//                 'Accept': 'application/json',
//                 'Authorization': `Bearer ${localStorage.getItem('access_token')}`
//             }
//         })
//         // console.log(response)
//         return response.data
//     } catch (error) {
//         console.log(error.response, 'error response')
//         return rejectWithValue(error)
//     }
// })

// export const getProductByCollection = createAsyncThunk('product/getProductByCollection', async (params, { rejectWithValue }) => {
//     try {
//         const response = await axios.get(`${apiUrl}/api/collection/${params.id}/product`, {
//             headers: {
//                 'Accept': 'application/json',
//                 'Authorization': `Bearer ${localStorage.getItem('access_token')}`
//             }
//         })
//         // console.log(response)
//         return response.data
//     } catch (error) {
//         console.log(error.response, 'error response')
//         return rejectWithValue(error)
//     }
// })


