import {
    Navigate,
} from "react-router-dom";

export const PublicRoute = ({ children }) => {

    const access_token = localStorage.getItem('access_token')
    const permissions = localStorage.getItem('permissions')
    const roles = localStorage.getItem('roles')
    const customer = localStorage.getItem('customer')
    const user = localStorage.getItem('user')


    if (access_token && permissions && roles && customer && user) return <Navigate to="/home" />
    return children;
}

export const PrivateRoute = ({ children }) => {

    const access_token = localStorage.getItem('access_token')
    const permissions = localStorage.getItem('permissions')
    const roles = localStorage.getItem('roles')
    const customer = localStorage.getItem('customer')
    const user = localStorage.getItem('user')

    if (!access_token && !permissions && !roles && !customer && !user) return <Navigate to="/" />
    return children;
}

export const rupiahFormat = (value) =>
    new Intl.NumberFormat("id-ID", {
        style: 'currency', // add Rp
        currency: "IDR",
        maximumSignificantDigits: 20
    }).format(value);
