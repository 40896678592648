import React, { useEffect, useState } from 'react'
import { Button, Table, Modal, notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { rupiahFormat } from '../../../app/helper'
import { getProduct } from '../product.api'
import { PlusCircleFilled, ShoppingCartOutlined } from '@ant-design/icons'
import { HiMinus, HiPlus } from 'react-icons/hi'
import { postAddToCart } from '../../cart/cart.api'
import { unwrapResult } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'

export default function ProductTable() {
    const { productList, loading } = useSelector(state => state.product)
    const { loadingCart } = useSelector(state => state.cart)

    const dispatch = useDispatch()

    const navigate = useNavigate()

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        dispatch(getProduct())
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [qty, setQty] = useState(1);
    const [ChoosenItem, setChoosenItem] = useState({});

    const handleAdd = () => {
        if (qty >= ChoosenItem.variants[0].stock) return;
        setQty(qty + 1)
    };
    const handleMin = () => {
        if (qty <= 1) return;
        setQty(qty - 1)
    };

    const showModal = (item) => {
        setIsModalOpen(true);
        setChoosenItem(item)
    };

    const handleOk = () => {
        // setIsModalOpen(false);
        const data = {
            quantity: qty,
            variant_id: ChoosenItem.variants[0].id
        }
        dispatch(postAddToCart(data)).then(unwrapResult)
            .then(res => {
                console.log(res)

                const key = `open${Date.now()}`;
                const btn = (
                    <Button type="primary" size="small" onClick={() => navigate('/cart')}>
                        Buka hamalam troli!
                    </Button>
                );
                notification.success({
                    message: 'Berhasil di tambahkan ke troli',
                    // placement: 'top',
                    key,
                    btn
                    // description:''
                });
                handleExitModal()
            })
            .catch(err => {
                console.log(err)
                Modal.error({
                    title: 'Error',
                    content: 'Gagal masuk ke troli!',
                });
            })
    };

    const handleExitModal = () => {
        setIsModalOpen(false);
        setQty(1);
        setChoosenItem({})
    };


    const columns = [
        {
            title: "NAMA",
            key: 'name',
            width: 150,
            render: (n) => <p className='text-dark'>
                <b className=''>{n?.attribute_data?.name}</b>
            </p>
        },
        {
            title: "JENIS",
            key: 'category',
            render: (n) => <><p>Lainnya</p></>
        },
        {
            title: "DESKRIPSI",
            key: 'desc',
            render: (n) => <small dangerouslySetInnerHTML={{ __html: n?.attribute_data?.description }} />
        },
        {
            title: "STOK",
            key: 'stock',
            width: 150,
            align: 'center',
            render: (v) => <>
                {
                    v?.variants?.map((res, i) => <div key={i}>{res?.stock} </div>)
                }
            </>,
        },
        {
            title: "HARGA",
            key: 'price',
            align: 'center',
            width: 150,
            render: (v) => <>
                {
                    v?.variants?.map((res, i) => <div>
                        <Button
                            key={i}
                            size=''
                            className='d-flex align-items-center w-100'
                            shape="round"
                            disabled={res.stock <= 1}
                            icon={<ShoppingCartOutlined />}
                            onClick={() => showModal(v)}
                        >
                            <b> &nbsp;
                                {rupiahFormat(res?.price?.value)}
                            </b>
                        </Button>
                    </div>)
                }
            </>,
        },
    ];

    return (
        <div className=''>
            <Modal
                title={<p className='text-center mb-0'>Masuk ke Troli</p>}
                open={isModalOpen}
                maskClosable={false}
                confirmLoading={loadingCart}
                closable={false}
                footer={[
                    <Button key="back" disabled={loadingCart} onClick={handleExitModal}>
                        Batal
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingCart} onClick={handleOk}>
                        Tambah
                    </Button>,
                ]}
            >
                <div className="pb-3 d-flex justify-content-between align-items-center border-bottom">
                    <p className='mb-0'>{ChoosenItem?.attribute_data?.name}</p>
                    <div className="d-flex justify-content-between align-items-center">
                        <Button disabled={loadingCart} type="primary" shape="circle" icon={<HiMinus />} onClick={handleMin} />
                        <p className='text-center mb-0' style={{ width: 35 }}>{qty}</p>
                        <Button disabled={loadingCart} type="primary" shape="circle" icon={<HiPlus />} onClick={handleAdd} />
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center pt-3">
                    <p className="mb-0"> <b>SUB TOTAL</b> </p>
                    <p className="mb-0">
                        {
                            ChoosenItem?.variants &&
                            <b>
                                {rupiahFormat(ChoosenItem?.variants[0]?.price?.value * qty)}
                            </b>
                        }
                    </p>
                </div>
            </Modal>

            <Table
                columns={columns}
                dataSource={productList}
                size="middle"
                loading={loading}
                rowClassName={(_, index) => index % 2 === 0 ? '' : 'rowdark'}
                pagination={{
                    size: 10,
                    showSizeChanger: true,
                    // onShowSizeChange: (e, v) => console.log(e, v),
                    showTotal: (total, range) => (
                        <span style={{ left: 0, position: "absolute", fontSize: 12 }}>
                            Showing {range[0]} to {range[1]} of {total} results
                        </span>
                    ),
                }}
                scroll={{
                    x: "100%",
                    // y: 300,
                }}
            />
        </div>
    )
}
