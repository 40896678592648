import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../config";

export const postLogin = createAsyncThunk('login/postLogin', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${apiUrl}/api/auth/login`, data)
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})