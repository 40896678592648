import React from 'react'
import LayoutApp from '../shared/components/LayoutApp'
import TableOrder from './components/TableOrder'


export default function Order() {
    return (
        <LayoutApp title='PESANAN'>
            <div className="invoiorderce">
                <TableOrder />
            </div>
        </LayoutApp>
    )
}
