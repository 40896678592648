import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { apiUrl } from "../../config"

export const getAddressList = createAsyncThunk('shared/getAddressList', async (params, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${apiUrl}/api/addresses`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
        // console.log(response)
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})



export const getShippingList = createAsyncThunk('shared/getShippingList', async (params, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${apiUrl}/api/shippings`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
        // console.log(response)
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})


export const getOrderStatuses = createAsyncThunk('shared/getOrderStatuses', async (params, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${apiUrl}/api/client/orders/statuses`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
        console.log(response.data)
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})