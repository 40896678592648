import React from 'react'
import LayoutApp from '../shared/components/LayoutApp'
import TableHistory from './components/TableHistory'



export default function History() {
    return (
        <LayoutApp title='RIWAYAT'>
            <div className="history">
                <TableHistory />
            </div>
        </LayoutApp>
    )
}
