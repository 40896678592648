import React from 'react'
import LayoutApp from '../shared/components/LayoutApp'
import CartTable from './components/CartTable'

export default function Cart() {
    return (
        <LayoutApp title='TROLI' >
            <div className="cart">
                <CartTable />
            </div>
        </LayoutApp>
    )
}
