import { createSlice } from "@reduxjs/toolkit";
import { getAddressList, getOrderStatuses, getShippingList } from "./shared.api";





const sharedSclice = createSlice({
    name: 'shared',
    initialState: {
        loadingAddress: false,
        loadingShipping: false,
        loadingStatuses: false,
        addressList: [],
        shippingList: [],
        orderStatusList: null,
        // primaryColor: '#244093',
        // secondaryColor: '#E54E26',
    },
    extraReducers: {
        //get address
        [getAddressList.pending]: (state, action) => {
            state.loadingAddress = true
        },
        [getAddressList.fulfilled]: (state, { payload }) => {
            state.loadingAddress = false
            state.addressList = payload.data
        },
        [getAddressList.rejected]: (state, action) => {
            state.loadingAddress = false
        },
        //get shippig list
        [getShippingList.pending]: (state, action) => {
            state.loadingShipping = true
        },
        [getShippingList.fulfilled]: (state, { payload }) => {
            state.loadingShipping = false
            state.shippingList = payload.data
            // console.log(payload.data, 'shipping list')
        },
        [getShippingList.rejected]: (state, action) => {
            state.loadingShipping = false
        },

        //get order statuses
        [getOrderStatuses.pending]: (state, action) => {
            state.loadingStatuses = true
        },
        [getOrderStatuses.fulfilled]: (state, { payload }) => {
            state.loadingStatuses = false
            state.orderStatusList = payload.data
            // console.log(payload.data, 'statuses')
        },
        [getOrderStatuses.rejected]: (state, action) => {
            state.loadingStatuses = false
        },
    }

})

export default sharedSclice.reducer