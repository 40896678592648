import { createSlice } from "@reduxjs/toolkit";
import { getCart, postAddToCart, postCheckoutCart } from "./invoice.api";




const invoiceSlice = createSlice({
    name: 'invoice',
    initialState: {
        loading: false,
        listInvoice: [],
    },
    extraReducers: {
        //get invoice
        // [getCart.pending]: (state, action) => {
        //     state.loadingCart = true
        // },
        // [getCart.fulfilled]: (state, { payload }) => {
        //     state.loadingCart = false
        //     console.log(payload.data.lines, 'cart')
        //     state.cartList = payload.data.lines
        //     state.cartAllData = payload.data
        // },
        // [getCart.rejected]: (state, action) => {
        //     state.loadingCart = false
        // },
    }

})

export default invoiceSlice.reducer