
import React, { useEffect, useState } from 'react'
import { Table, Tag } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { rupiahFormat } from '../../../app/helper'
import { CheckCircleOutlined } from '@ant-design/icons'

export default function TableHistory() {
    const { loading } = useSelector(state => state.invoice)

    // const dispatch = useDispatch()

    useEffect(() => {
        // dispatch(getCart())
    }, [])

    const dummy = [
        { invoiceId: 3231, purchaseOrderId: 32232, total: 2600000, status: 'done' },
        { invoiceId: 3231, purchaseOrderId: 32232, total: 2600000, status: 'done' },
        { invoiceId: 3231, purchaseOrderId: 32232, total: 2600000, status: 'done' },
        { invoiceId: 3231, purchaseOrderId: 32232, total: 2600000, status: 'done' },
        { invoiceId: 3231, purchaseOrderId: 32232, total: 2600000, status: 'done' },
        { invoiceId: 3231, purchaseOrderId: 32232, total: 2600000, status: 'reject' },
        { invoiceId: 3231, purchaseOrderId: 32232, total: 2600000, status: 'reject' },

    ]

    return (
        <div className=''>
            <Table
                columns={[
                    {
                        title: "No",
                        width: 50,
                        render: (item, arr, i) => <p className='text-dark'>
                            <b className=''>{i + 1}</b>
                        </p>
                    },
                    {
                        title: "INVOICE ID",
                        // width: 150,
                        render: (n) => <p className='text-dark'>
                            <b className=''>{n?.invoiceId}</b>
                        </p>
                    },
                    {
                        title: "Purchase Order ID",
                        render: (n) => <><p>{n.purchaseOrderId}</p></>
                    },
                    {
                        title: "Total Tagihan ",
                        render: (n) => <p>{rupiahFormat(n.total)} </p>
                    },
                    {
                        title: "status",
                        render: (n) => <p>
                            {n.status === 'done' ?
                                <Tag className='' color="success">Done</Tag>
                                :
                                <Tag color="error">Rejected</Tag>
                            }
                        </p>
                    },
                    // {
                    //     title: "HARGA",
                    //     align: 'center',
                    //     width: 150,
                    //     render: (v) => <>
                    //         {
                    //             v?.variants?.map(res => <div>
                    //                 <Button
                    //                     size=''
                    //                     className='d-flex align-items-center w-100'
                    //                     shape="round"
                    //                     icon={<ShoppingCartOutlined />}
                    //                     // onClick={() => showModal(v)}
                    //                 >
                    //                     <b> &nbsp;
                    //                         {rupiahFormat(res?.price?.value)}
                    //                     </b>
                    //                 </Button>
                    //             </div>)
                    //         }
                    //     </>,
                    // },
                ]}
                dataSource={dummy}
                size="middle"
                loading={loading}
                rowClassName={(_, index) => index % 2 === 0 ? '' : 'rowdark'}
                pagination={{
                    size: 10,
                    showSizeChanger: true,
                    // onShowSizeChange: (e, v) => console.log(e, v),
                    showTotal: (total, range) => (
                        <span style={{ left: 0, position: "absolute", fontSize: 12 }}>
                            Showing {range[0]} to {range[1]} of {total} results
                        </span>
                    ),
                }}
                scroll={{
                    x: "100%",
                    // y: 300,
                }}
            />

        </div>
    )
}
