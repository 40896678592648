import { createSlice } from "@reduxjs/toolkit";
import { getCart, postAddToCart, postCheckoutCart } from "./cart.api";




const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        loadingCart: false,
        loadingCheckout: false,
        cartList: [],
        cartAllData: {}
    },
    extraReducers: {
        [postAddToCart.pending]: (state, action) => {
            state.loadingCart = true
        },
        [postAddToCart.fulfilled]: (state, { payload }) => {
            state.loadingCart = false
        },
        [postAddToCart.rejected]: (state, action) => {
            state.loadingCart = false
        },
        //getcart
        [getCart.pending]: (state, action) => {
            state.loadingCart = true
        },
        [getCart.fulfilled]: (state, { payload }) => {
            state.loadingCart = false
            console.log(payload?.data?.lines, 'cart')
            state.cartList = payload?.data?.lines
            state.cartAllData = payload?.data
        },
        [getCart.rejected]: (state, action) => {
            state.loadingCart = false
        },
        //post checkout
        [postCheckoutCart.pending]: (state, action) => {
            state.loadingCheckout = true
        },
        [postCheckoutCart.fulfilled]: (state, { payload }) => {
            state.loadingCheckout = false
        },
        [postCheckoutCart.rejected]: (state, action) => {
            state.loadingCheckout = false
        },
    }

})

export default cartSlice.reducer