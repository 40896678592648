import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/auth.reducer';
import cartReducer from '../features/cart/cart.reducer';
import historyReducer from '../features/history/history.reducer';
import invoiceReducer from '../features/invoice/invoice.reducer';
import orderReducer from '../features/order/order.reducer';
import productReducer from '../features/product/product.reducer';
import sharedReducer from '../features/shared/shared.reducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    product: productReducer,
    cart: cartReducer,
    order: orderReducer,
    invoice: invoiceReducer,
    history: historyReducer,
    shared: sharedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
