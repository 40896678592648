import {
    HiBookOpen,
} from "react-icons/hi";
import Auth from "../features/auth/Auth";
import Playground from "../features/playground/Playground";
import Product from "../features/product/Product";

import Cart from "../features/cart/Cart";
import Invoice from "../features/invoice/Invoice";
import Order from "../features/order/Order";
import History from "../features/history/History";

export default [
    {
        name: 'Auth',
        isSidebarLink: true,
        path: '/',
        element: <Auth />,
        isPrivate: false
    },
    {
        name: 'Playground',
        isSidebarLink: true,
        path: '/play',
        element: <Playground />,
        isPrivate: false
    },
    {
        name: 'Home',
        isSidebarLink: true,
        path: '/home',
        element: <Product />,
        isPrivate: true
    },
    {
        name: 'Cart',
        isSidebarLink: true,
        path: '/cart',
        element: <Cart />,
        isPrivate: true
    },
    {
        name: 'Order',
        isSidebarLink: true,
        path: '/order',
        element: <Order />,
        isPrivate: true
    },
    {
        name: 'Invoice',
        isSidebarLink: true,
        path: '/invoice',
        element: <Invoice />,
        isPrivate: true
    },
    {
        name: 'History',
        isSidebarLink: true,
        path: '/history',
        element: <History />,
        isPrivate: true
    },
]